import { useState, useEffect } from "react";
import { verifyAuthentication } from "./../../api/auth";

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    verifyAuthentication()
      .then((authStatus) => {
        setIsAuthenticated(authStatus.isAuthenticated);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Authentication error:", error);
        setIsLoading(false);
      });
  }, []);

  return { isAuthenticated, isLoading, setIsAuthenticated };
};

export default useAuth;
