import axios from "axios";

const apiDomain = process.env.REACT_APP_API_DOMAIN || "https://localhost:3000";
const URL = `${apiDomain}/api/users`;

const getUserInfo = async (cb) => {
  try {
    let result = await axios({
      method: "GET",
      withCredentials: "include",
      url: `${URL}/info`,
    });
    return cb(result.data);
  } catch (error) {
    console.error("Error getting user info:", error);
    return error.response.data;
  }
};
const authUpdateImage = async (data) => {
  try {
    let result = await axios({
      method: "POST",
      withCredentials: "include",
      url: `${URL}/upload/images`,
      data: data,
    });
    return result;
  } catch (error) {
    console.error("Error updating image:", error);
    return error.response;
  }
};

const authResetImage = async () => {
  try {
    let result = await axios({
      method: "PUT",
      withCredentials: "include",
      url: `${URL}/reset-images`,
    });
    return result;
  } catch (error) {
    console.error("Error resetting image:", error);
    return error.response;
  }
}

const authUpdateProfile = async (data) => {

  try {
    let result = await axios({
      method: "PUT",
      withCredentials: "include",
      url: `${URL}/profile`,
      data: data,
    });
    return result;
  } catch (error) {
    console.error("Error updating profile:", error);
    return error.response;
  }
}
export { getUserInfo, authUpdateImage, authResetImage, authUpdateProfile };
