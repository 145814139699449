import { useEffect } from "react";
import io from "socket.io-client";
import { getUserInfo } from "../../api/user";
import Swal from "sweetalert2";

const SOCKET_SERVER_URL = process.env.REACT_APP_API_DOMAIN;

const useSocket = (isAuthenticated, handleCampaignUpdate) => {
  useEffect(() => {
    if (!isAuthenticated) return;

    let socket; // Deklarasi socket di luar agar bisa diakses oleh cleanup function

    const connectSocket = async () => {
      try {
        let userId = null;

        await getUserInfo((cb) => {
          userId = cb.data.id;
        });

        
        socket = io(SOCKET_SERVER_URL);

        socket.on("connect", () => {
          console.log("Connected to socket server");
          socket.emit("register", userId);
        });

        socket.on("disconnect", () => {
          console.log("Disconnected from socket server");
        });

        socket.on("campaignUpdate", (data) => {
          if (data.status !== "error") {
            if (typeof handleCampaignUpdate === "function") {
              handleCampaignUpdate(data);
            } else {
              console.error("handleCampaignUpdate is not a function");
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }
        });

        socket.on("campaignEnd", (data) => {
          console.log("Campaign ended:", data);
          Swal.fire({
            icon: "success",
            title: `${data.campaign_name} Telah Berakhir`,
            text: `Kampanye berakhir dengan sisa durasi anda adalah ${data.duration} detik.`,
          });
        });

        socket.on("error", (error) => {
          console.error("Socket error:", error);
        });
      } catch (error) {
        console.error("Failed to connect or get user info:", error);
      }
    };

    connectSocket();

    return () => {
      console.log("Disconnecting from socket");
      if (socket) socket.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Dependensi useEffect
};

export default useSocket;
