import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Context
import { ThemeProvider } from "./context/ThemeContext";

// Hooks
import PreloaderNavigation from "./hooks/PreloaderNavigation";
import useAuth from "./hooks/isAuth";
import useSocket from "./hooks/useSocket";

// Components
const Preloader = lazy(() => import("./components/Preloader"));
const GreetingToast = lazy(() => import("./components/Toast/GreetingToast"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const Sidebar = lazy(() => import("./components/Navbar"));
const Header = lazy(() => import("./components/Header"));
const AccountSetting = lazy(() => import("./components/AccountSetting"));
const Campaign = lazy(() => import("./components/Campaign"));
const Contact = lazy(() => import("./components/Contact"));
const LiveCampaign = lazy(() => import("./components/LiveCampaign"));
const Report = lazy(() => import("./components/Report"));
const NotFound = lazy(() => import("./components/NotFound"));
const SwitcherTheme = lazy(() => import("./components/Switcher Theme"));

// Pages
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));

// Protected Route
const ProtectedRoute = lazy(() => import("./hooks/ProtectedRoute"));

// Layout
const BodyWrapperLayout = lazy(() => import("./components/Body Wrapper"));

function App() {
  const { isAuthenticated, isLoading, setIsAuthenticated } = useAuth();

  return (
    <Router>
      <ThemeProvider>
        <Suspense fallback={<Preloader />}>
          <Main
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticated}
            isLoading={isLoading}
          />
        </Suspense>
      </ThemeProvider>
    </Router>
  );
}

function Main({ isAuthenticated, isLoading, setIsAuthenticated }) {
  const isNavigating = PreloaderNavigation();
  useSocket(isAuthenticated);

  return (
    <>
      {isNavigating && <Preloader />}
      <GreetingToast />
      <div id="main-wrapper">
        <Suspense fallback={<Preloader />}>
          <div
            className={isAuthenticated ? "page-wrapper" : "page-wrapper-auth"}
          >
            {isAuthenticated && (
              <>
                <Header setIsAuthenticated={setIsAuthenticated} />
                <Sidebar setIsAuthenticated={setIsAuthenticated} />
              </>
            )}
            <Routes>
              <Route
                path="/auth/login"
                element={
                  <Login
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                  />
                }
              />
              <Route
                path="/auth/forgot-password"
                element={<ForgotPassword isAuthenticated={isAuthenticated} />}
              />
              <Route
                path="/auth/reset-password/:token"
                element={
                  <ResetPassword
                    isAuthenticated={isAuthenticated}
                  />
                }
              />
              <Route
                path="/"
                element={
                  <ProtectedRoute
                    isAuthenticated={isAuthenticated}
                    isLoading={isLoading}
                  >
                    <BodyWrapperLayout>
                      <Dashboard />
                    </BodyWrapperLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-profile"
                element={
                  <ProtectedRoute
                    isAuthenticated={isAuthenticated}
                    isLoading={isLoading}
                  >
                    <BodyWrapperLayout>
                      <AccountSetting />
                    </BodyWrapperLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/campaigns"
                element={
                  <ProtectedRoute
                    isAuthenticated={isAuthenticated}
                    isLoading={isLoading}
                  >
                    <BodyWrapperLayout>
                      <Campaign />
                    </BodyWrapperLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/trackingCampaign/"
                element={
                  <ProtectedRoute
                    isAuthenticated={isAuthenticated}
                    isLoading={isLoading}
                  >
                    <BodyWrapperLayout>
                      <LiveCampaign isAuthenticated={isAuthenticated} />
                    </BodyWrapperLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/contacts"
                element={
                  <ProtectedRoute
                    isAuthenticated={isAuthenticated}
                    isLoading={isLoading}
                  >
                    <BodyWrapperLayout>
                      <Contact />
                    </BodyWrapperLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reports"
                element={
                  <ProtectedRoute
                    isAuthenticated={isAuthenticated}
                    isLoading={isLoading}
                  >
                    <BodyWrapperLayout>
                      <Report />
                    </BodyWrapperLayout>
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>

          </div>
        </Suspense>
        {isAuthenticated && <SwitcherTheme />}

      </div>
      <div className="dark-transparent sidebartoggler"></div>
    </>
  );
}

export default App;
